import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PageHeading from "../components/PageHeading"
import PromoVideo from "../components/PromoVideo"
import ImageText from "../components/ImageText"
import TextArea from "../components/TextArea"
import PureVideo from "../components/PureVideo"
import MoveAndStretchVideo from "../assets/videos/move-and-stretch.mp4"
import "../assets/sass/style.scss"

const Classes = ({ props }) => {
  return (
    <Layout>
      <SEO
        title="Classes &amp; Training"
        description="Laura offers both structured Pilates classes as well as offering specially tailored Personal Training"
      />

      <PageHeading text="Classes &amp; Training"></PageHeading>

      <ImageText
        className="image-text--reverse bg-grey"
        heading="Personal Training With Laura"
        imageSrc="not_kim_wilde.jpg"
        imageAlt="Personal Training photograph"
      >
        <p>Laura’s PT checklist:</p>
        <ul>
          <li>Tough sessions that enable you to work harder than you would on your own.</li>
          <li>Nutrition advice and food diary analysis.</li>
          <li>Midweek check-ins to help you stick to the plan.</li>
          <li>Ongoing, practical support to enable you to achieve your goals.</li>
        </ul>
        <p>Laura offers both in-person and online training.</p>
        <p>
          <em>If you’d like to know more about one-to-one sessions or joining a class, 
            drop Laura an email and she’ll get back to you.</em>
        </p>
        <Link to="/contact" className="btn btn--primary">
          Get in Touch
        </Link>
      </ImageText>

      <PromoVideo
        cssClass="promo-video bg-yellow"
        videoSrcURL="https://player.vimeo.com/video/522408766?loop=1"
      >
      </PromoVideo>

      <ImageText
        className="image-text bg-red"
        heading="Other Fitness Sessions Laura Leads"
        imageSrc="laura_goodgym_wheelbarrow.jpg"
        imageAlt="Other classes photograph"
      >
        <h3><strong><em>GoodGym</em></strong></h3>
        <p>
          Laura is the Tower Hamlets Area Lead for the fitness and volunteering charity, <em>GoodGym</em>. 

          
          She leads a weekly Monday evening session which consists of a physical, community-based task that usually doubles-up as a good workout!
          <br />
          Visit the GoodGym site to find out more.
        </p>
        <Link to="https://www.goodgym.org/v3/profile/laura-williams-6ffd542e-7c9e-4a4a-a5c7-1a560e611b0e" className="btn btn--primary">
          Visit GoodGym
        </Link>
        <br /><br />
        <div className="twitter-video">
          <PureVideo src={MoveAndStretchVideo} poster="move-and-stretch-video-poster.jpg" />
          <div className="copyright"><a href="https://twitter.com/WeAreWellOne" target="_blank">&copy; Well One</a></div>
        </div>
        <h3><strong><em>Move and Stretch</em></strong></h3>
        <p>
        This free, weekly session for women is run by East London-based organisation, <em>Trapped in Zone One</em>, with sessions taught by Laura.
        <br />
        <br />
        <em>Move and Stretch</em> takes place every Wednesday morning, from 10-12, in Aberfeldy, in Poplar.
        </p>
        <Link to="https://www.trappedinzoneone.com/projects/move-and-stretch" className="btn btn--primary">
          Read more and sign-up
        </Link>
      </ImageText>

      <TextArea cssClass="text-area" heading="Health and Hygiene">
        <p>
          Your safety and peace of mind are important. The current measures remain in place:
        </p>
        <ul>
          <li>Small class sizes.</li>
          <li>Well-ventilated venues.</li>
          <li>Surfaces regularly cleaned.</li>
          <li>Participants bring own mats.</li>
          <li>Equipment for all sessions cleaned after use.</li>
          <li>Participants advised not to attend if unwell, and offered a full refund.</li>
          <li>Risk assessments available to view, for classes and personal training sessions.</li>
        </ul>
        <p>
          <a href="/contact">Contact Laura</a> with any queries or concerns.
        </p>
      </TextArea>
    </Layout>
  )
}

export default Classes
